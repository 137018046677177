import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Home from "./components/Home";
import Admin from "./components/admin/Adminuniversity";
import AdminEngineering from "./components/admin/AdminEngineering";
import AdminDesign from "./components/admin/AdminDesign";
import AdminManagement from "./components/admin/AdminManagement";
import AdminLaw from "./components/admin/AdminLaw";
import Header from "./components/Header";
import MBBS from "./components/Medicalcourses/MBBS";
import Design from "./components/Medicalcourses/Design";
import Law from "./components/Medicalcourses/Law";
import Management from "./components/Medicalcourses/Management";
import Collegepage from "./components/Collegepage/Collegepage";
import Designpage from "./components/Collegepage/Designpage";
import Engineeringpage from "./components/Collegepage/Enginneringpage";
import Lawpage from "./components/Collegepage/Lawpage";
import Managementpage from "./components/Collegepage/Managementpage";
import Engineering from "./components/Medicalcourses/Engineering";

function App() {
 
  return (
    <div className="App">
      <Router>
        
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
       
        
      </Router>
    </div>
  );
}

export default App;
