import React from 'react'
import './Home.css'
import { Box , HStack ,Text , VStack ,Flex,Button,  Stack, useDisclosure,Img} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link,IconButton,Menu,MenuButton,MenuList,MenuItem,Grid,GridItem} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import img46 from './logo.png'

import img1 from './Vector1.png'
import img2 from './vector2.png'
import img3 from './vector3.png'
import img4 from './Vector4.png'
import img5 from './vector5.png'
import img6 from './vector6.png'
import img7 from './vector7.png'
import img8 from './vector8.png'
import img9 from './vector9.png'
import img10 from './vector10.png'
import img11 from './vector11.png'
import img12 from './vector12.png'
import img13 from './vector13.png'
import img14 from './vector14.png'
import img15 from './vector15.png'
import img16 from './vector16.png'
import img17 from './vector17.png'
import img18 from './vector18.png'
import img19 from './vector19.png'
import img20 from './vector20.png'
import img21 from './vector21.png'
import img22 from './vector22.png'
import img23 from './vector23.png'
import img24 from './vector24.png'
import img25 from './vcetor18.png'
import img26 from './vector26.png'
import img28 from './vector28.png'
import img27 from './vector27.png'
import img30 from './imag1.png'
import img31 from './imag2.png'
import img32 from './imag3.png'
import img33 from './imag4.png'
import img34 from './vcetor29.png'
import img35 from './Vector30.png'
import thumb from './thumb icon.png'
import img36 from './studentimage1.png'
import img37 from './vector31.png'
import img38 from './vector32.png'
import img39 from './blog1.png'
import img40 from './blog2.png'
import img41 from './blog3.png'
import img42 from './blog4.png'
import img43 from './vector33.png'
import img44 from './vector34.png'
import img45 from './vectoe35.png'
import img47 from './test2.png'
import img48 from './test3.png'
import img49 from './test4.png'
const Home = () => {

  const { isOpen, onOpen, onClose } = useDisclosure();

const navigate=useNavigate();
  const handleEngineering=()=>{
    navigate('/Engineering')

  }
  const handlemanagement=()=>{
    navigate('/management')

  }
  const handlemedical=()=>{
    navigate('/medical')

  }
  const handlelaw=()=>{
    navigate('/law')

  }

  const handleDesign=()=>{
    navigate('/design')

  }
  return (
    <div>
        <Box className="banner" width={'100vw'} height={['75vh','70vh','62vh','62vh']} style={{width:'100vw',position:"relative"}} backgroundColor={['blue','none','none','none']}>


        <Box fontSize={['3rem','3rem','4rem','4rem']} color={'white'} zIndex={'1'} position={'absolute'} top={'10vh'} left={'10vw'} 
         marginBottom={'18'} width={['80%','80%','40%','40%']}> 



        

       
        <Text>Your College</Text>
       
        <Text>Your Future</Text>
<Text fontSize={'1rem'} width={'80%'} color={'rgba(255, 255, 255, 0.8)'}>
        Discover an extensive collection of colleges and universities worldwide, opening doors to endless educational possibilities
        </Text>

        <Flex  width={'60%'} marginTop={'2vh'}>
<Button padding={'7px 18px'} fontSize={['0.7rem','1rem','1rem','1rem']} background={'white'} color={'black'} fontFamily={'Jost'} fontWeight={'normal'} marginRight={'5vw'}>
  Register now

</Button>

<Button padding={'7px 18px'} fontSize={['0.7rem','1rem','1rem','1rem']} background={'#FF6C00'} color={'black'} fontFamily={'Jost'} fontWeight={'normal'}>
  Contact now

</Button>
        </Flex>
        </Box>

        <Box>
       
        </Box>

        <br/>
       

        
        

         

        <Stack
        height="10vh"
        spacing={8}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <Box display={["block", "block", "none", "none"]} position={'relative'} width={'100%'}>
          <Menu >
            <MenuButton
              as={IconButton}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              onClick={isOpen ? onClose : onOpen}
              aria-label={isOpen ? "Close Menu" : "Open Menu"}
            />
            <MenuList width={'100%'}>
              <MenuItem to="/">Home</MenuItem>
              <MenuItem to="/about">About</MenuItem>
              <MenuItem to="/assessment">Assessment</MenuItem>
              <MenuItem to="/studyabroad">Study Abroad</MenuItem>
              <MenuItem to="/Jobs In India">Jobs In India</MenuItem>
              <MenuItem to="/carrier">Carrier</MenuItem>
              <MenuItem to="/faqs">FAQS</MenuItem>
              <MenuItem to="/blog">Blog</MenuItem>
              <MenuItem to="/contact">Contact Us</MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box display={["none", "none", "block", "block"]}  spacing={8} mr={'7vw'} >

         
            <img src={img46} style={{position:'absolute',top:'2vw',left:"3vw"}}></img>

          

          
          <Link href="/" ml={'15px'}>Home</Link>
          <Link href="/about" ml={'15px'}>About</Link>
          <Link href="/assessment" ml={'15px'}>Assessment</Link>
          <Link href="/studyabroad" ml={'15px'}>Study Abroad</Link>
          <Link href="/Jobs In India" ml={'15px'}>Jobs In India</Link>
          <Link href="/carrier" ml={'15px'}>Carrier</Link>
          <Link href="/faqs" ml={'15px'}>FAQS</Link>
          <Link href="/blog" ml={'15px'}>Blog</Link>
          <Link href="/contact" ml={'15px'}>Contact Us</Link>

         

          
        </Box>
      </Stack>

      <HStack position={'absolute'} bottom={'-6vh'} left={['2vw','10vw','25vw','25vw']} width={'80vw'}>

<Box  style={{background:"white",padding:'19px',fontSize:"21px",boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.5)',borderRadius:'7px'}} height={['12vh','11vh','9vh','9vh']} width={['40%','40%','18%','18%']} display={'flex'}   justifyContent={'center'} alignItems={'center'}>
    <Stack justifyContent={'space-between'} fontSize={'1.2rem'} fontWeight={'medium'} width={'100%'} direction={['column','column','row','row']} align={'center'}>
    <img src={img1} ></img>
    <Text >College</Text>
    </Stack>

</Box>

<Box  style={{background:"white",padding:'19px',fontSize:"21px",boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.5)',borderRadius:'7px'}} height={['12vh','11vh','9vh','9vh']} width={['40%','40%','18%','18%']} display={'flex'}  justifyContent={'center'} alignItems={'center'}>
    <Stack justifyContent={'space-between'} fontSize={'1.2rem'} fontWeight={'medium'} width={'100%'} direction={['column','column','row','row']} align={'center'}>
    <img src={img2} ></img>
    <Text >Exams</Text>
    </Stack>

</Box>


<Box style={{background:"white",padding:'19px',fontSize:"1.2rem",boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.5)',borderRadius:'7px',height:'9vh'}} width={['40%','40%','18%','18%']} display={['none','flex','flex','flex']}  alignItems={'center'}>
    <Stack justifyContent={'space-between'} fontSize={'1.2rem'} fontWeight={'medium'} width={'100%'} direction={['column','column','row','row']} align={'center'}>
    <img src={img3} ></img>
    <Text >Review</Text>
    </Stack>

</Box>

<Box style={{background:"white",padding:'19px',fontSize:"21px",boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.5)',borderRadius:'7px'}} width={['40%','40%','18%','18%']} display={'flex'}  alignItems={'center'} height={['12vh','11vh','9vh','9vh']}>
    <Stack justifyContent={'space-between'} fontSize={'1.2rem'} fontWeight={'medium'} width={'100%'} direction={['column','column','row','row']} align={'center'}>
    <img src={img4} ></img>
    <Text >Courses</Text>
    </Stack>

</Box>

    
  





</HStack>


   








        </Box>


        <Box style={{width:"99vw"}} height={['80vh','80vh','39vh','39vh']} marginTop={['11vh','11vh','11vh','11vh']} >
<Stack marginTop={'7vh'} width={'75%'} margin={'auto'} display={'flex'} justifyContent= 'space-between' alignItems={'center'} direction={['column','column','row','row']}>

  <Box width={['90vw','90vw','35vw','35vw']} height={'36vh'} background={'white'} boxShadow= '15px 4px 64px rgba(0, 0, 0, 0.15)' display={'flex'} justifyContent={'center'} borderBottom= {'4px solid #FF6C00'} alignItems={'center'} borderRadius={'20px'} position={'relative'} >
    <HStack width={'100%'} height={'100%'}>
      
<Box width={['90vw','90vw','35vw','35vw']} height={'100%'} background={'white'} boxShadow= '15px 4px 64px rgba(0, 0, 0, 0.15)' display={'flex'} justifyContent={'center'}  alignItems={'center'} borderRadius={'20px'} position={'relative'} >
  <Box position={'absolute'} top={'0px'} left={'0px'} width={['50vw','40vw','15vw','15vw']} height={'7vh'} display={'flex'} justifyContent={'center'} alignItems={'center'} background={'#00008B'} fontSize={'1.9rem'} color={'white'} borderRadius= {'0px 0px 20px 20px'} fontWeight={'SemiBold'}>

<Text fontSize={['1.5rem','1.9rem','1.9rem','1.9rem']}>Study In India</Text>












</Box>
    <HStack width={'100%'}  display={'flex'} justifyContent = {'space-between'} alignItems={'center'}>
      
      <Box width={'65%'} fontSize={['0.6rem','1rem','1rem','1rem']} marginTop={'8vh'}>
      Embark on an Enlightening Journey: Study in India. Immerse yourself in a diverse and culturally rich educational experience. Explore renowned institutions, embrace academic excellence, and shape your future in a land of opportunity. 
      </Box>
      <Box>
        <img src={img5}></img>
      </Box>

    </HStack>

  </Box>

    </HStack>

  </Box>

  <Box width={['90vw','90vw','35vw','35vw']} height={'36vh'} background={'white'} boxShadow= '15px 4px 64px rgba(0, 0, 0, 0.15)' display={'flex'} justifyContent={'center'}  alignItems={'center'} borderRadius={'20px'} position={'relative'} >
    <HStack width={'100%'} height={'100%'}>
      
<Box width={['90vw','90vw','35vw','35vw']} height={'100%'} background={'white'} boxShadow= '15px 4px 64px rgba(0, 0, 0, 0.15)' display={'flex'} justifyContent={'center'}  alignItems={'center'} borderRadius={'20px'} position={'relative'} >
  <Box position={'absolute'} top={'0px'} left={'0px'} width={['50vw','40vw','15vw','15vw']} height={'7vh'} display={'flex'} justifyContent={'center'} alignItems={'center'} background={'#00008B'} fontSize={'1.9rem'} color={'white'} borderRadius= {'0px 0px 20px 20px'} fontWeight={'SemiBold'}>

<Text fontSize={['1.5rem','1.9rem','1.9rem','1.9rem']}>Study Abroad</Text>












</Box>
    <HStack width={'100%'}  display={'flex'} justifyContent = {'space-between'} alignItems={'center'}>
      
      <Box width={'65%'} fontSize={['0.6rem','1rem','1rem','1rem']} marginTop={'8vh'}>
      Explore Global Horizons: Study Abroad. Expand your horizons with international education. Immerse yourself in diverse cultures, gain a world-class education, and make unforgettable memories. Your journey to academic and personal growth begins here.
      </Box>
      <Box>
        <img src={img6}></img>
      </Box>

    </HStack>

  </Box>

    </HStack>

  </Box>
</Stack>
</Box>



<Box width={'100vw'} height={['100vh','100vh','60vh','60vh']} backgroundColor={'#FAFAFA'} display={'flex'} justifyContent={'center'} alignItems={'center'} position={'relative'}>

  <Stack direction={['column','column','row','row']} justifyContent={'center'} alignItems={'center'}>
  <Box width={['90vw','90vw','30vw','30vw']} height={'45vh'} display={'flex'}>

  <HStack>

    <VStack width={['45%','45%','40%','40%']} height={'100%'}>
    <Box width={'100%'} height={'27%'} backgroundColor={'#FF6C00'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} color={'white'} borderRadius={'5px'} marginTop={'2vh'}>
      <Text fontSize={['1.2rem','1.3rem','1.5rem','1.5rem']} fontWeight={'500'}>12 Years Of</Text>
      <Text fontSize={['0.8rem','0.8rem','1rem','1rem']}>Experience</Text>
    </Box>

    <Box width={'100%'} height={'73%'}  boxShadow= {'15px 4px 64px rgba(0, 0, 0, 0.15)'} background= {'#FFFFFF'}>
      <img src={img7} style={{width:'90%',height:'90%'}}></img>
    </Box>

    </VStack>



    <VStack width={['45%','45%','40%','40%']} height={'100%'} marginLeft={'4vw'} marginTop={'12vh'}>
    <Box width={'100%'} height={'70%'}  boxShadow= {'15px 4px 64px rgba(0, 0, 0, 0.15)'} background= {'#FFFFFF'}>
      <img src={img8} style={{width:'90%',height:'90%'}}></img>
    </Box>
    <Box width={'100%'} height={'30%'} backgroundColor={'#00008B'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} color={'white'} borderRadius={'5px'} >
      <Text fontSize={['1.5rem','1.5rem','1.9rem','1.9rem']} fontWeight={'500'}>35K</Text>
      <Text fontSize={['0.8rem','0.8rem','1.1rem','1.1rem']}>Positive Reviews</Text>
    </Box>

    

    </VStack>



  </HStack>
  


</Box>
  <Box width={['90vw','90vw','26vw','26vw']} marginLeft={'4vw'} >

    <Text fontSize={'2rem'} fontWeight={'600'} marginTop={'2vh'}> Who Are We ?</Text>
    <hr style={{width:'14vw',height:"0.4vh",background:'#FF6C00',border:'none',marginTop:'-1.8vh'}} / >

      <Text marginTop={'4vh'} fontSize={'1rem'}>Welcome to Asianlink Academy, your trusted partner for all your study abroad and PR service needs! With over 8 years of experience, Asianlink Academy has been assisting students in realizing their dreams of studying abroad and providing expert services for visa, work permit assistance, medical programs, MBBS, and PR services. Our team of seasoned professionals and international experts are committed to delivering excellence in every aspect of our services, making us a leading name in the industry.</Text>

  </Box>

    
  </Stack>

<Box position={'absolute'} display={['none','none','block','block']} top={'5px'} left={'2vw'}>
    <img src={img9}></img>

  </Box>

  <Box position={'absolute'} display={['none','none','block','block']} bottom={'5px'} right={'2vw'}>
    <img src={img10}></img>

  </Box>

 

  

</Box>



<Box width={'100vw'} marginTop={'4vh'} height={['300vh','200vh','110vh','110vh']} backgroundColor={'#00008B'} display={'flex '}  flexDirection={'column'} alignItems={'center'} justifyContent={'center'} position={'relative'}> 
  <Box display={'flex '} width={'100vw'}  flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
    <Box color={'white'}>
    <Text fontSize={'1.3rem'} color={ 'rgba(255, 255, 255, 0.9)'} fontWeight={'500'} textAlign={'center'}>Top Classes Courses</Text>

<Text fontSize={['1.7rem','2rem','2.5rem','3rem']} textAlign={'center'}  fontWeight={'550'}>Explore 4000+ Courses</Text>
<br/>
<Text fontSize={'1.5rem'} textAlign={'center'} color={ 'rgba(255, 255, 255, 0.9)'} fontWeight={'100'}>Find Information By category</Text>
<br/>

<HStack>
  <Box width={['30vw','30vw','13vw','13vw']} height={'7vh'} background={'white'} borderRadius={'5px'} backgroundColor={'#FF6537'} display={'flex'} justifyContent={'center'} alignItems={'center'} marginRight={'1vw'}>
    <Text fontSize={['1.1rem','1.1rem','1.4rem','1.4rem']}>Colleges</Text>

  </Box>

  <Box width={['30vw','30vw','13vw','13vw']} height={'7vh'} background={'white'} borderRadius={'5px'} backgroundColor={'#FFFFFF'} display={'flex'} justifyContent={'center'} alignItems={'center'} marginRight={'1vw'}>
    <Text fontSize={['1.1rem','1.1rem','1.4rem','1.4rem']} color={'#00008B'}>Courses</Text>

  </Box>

  <Box width={['30vw','30vw','13vw','13vw']} height={'7vh'} background={'white'} borderRadius={'5px'} backgroundColor={'#FFFFFF'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
  <Text fontSize={['1.1rem','1.1rem','1.4rem','1.4rem']} color={'#00008B'}>Locations</Text>

  </Box>
</HStack>






    </Box>

    <Box >


 <Grid
  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(5, 1fr)" }}
  gap={6}
  width={{ base: '80vw', md: '90%',lg:"90%",xl:'90%' }}

>
  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} onClick={handleEngineering} cursor="pointer" width={'100%'} height={'14vh'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="100%">
        <img src={img11} width="100%" height="100%" alt="Engineering" />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Engineering</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} onClick={handlemanagement} cursor="pointer" height={'14vh'} width={'100%'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img12} width="100%" height="100%" alt="Management" />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Management</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} onClick={handlemedical} cursor="pointer" height={'14vh'} width={'100%'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img13} width="100%" height="100%" alt="Medical" />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Medical</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} cursor="pointer" width={'100%'} height={'14vh'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img14} width="100%" height="100%" alt="Communication" />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.1rem" marginTop="-0.5vh">Communication</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} onClick={handlelaw} cursor="pointer" width={'100%'} height={'14vh'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img15} width="100%" height="100%" alt="Law" />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Law</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>
</Grid>

 <Grid
  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(5, 1fr)" }}
  gap={6}
  width={{ base: '80vw', md: '90%',lg:"90%",xl:'90%' }}

>
  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} cursor="pointer" width={'100%'} height={'14vh'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="100%">
        <img src={img16} width="100%" height="100%"  />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Architecture</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} onClick={handleDesign} cursor="pointer" height={'14vh'} width={'100%'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img24} width="100%" height="100%" alt="Management" />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Design</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} onClick={handlemedical} cursor="pointer" height={'14vh'} width={'100%'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img17} width="100%" height="100%"  />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Computer</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} cursor="pointer" width={'100%'} height={'14vh'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img25} width="100%" height="100%" alt="Communication" />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.1rem" marginTop="-0.5vh">Art & <br/> humanities</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} onClick={handlelaw} cursor="pointer" width={'100%'} height={'14vh'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img18} width="100%" height="100%" alt="Law" />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Commerce</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>
</Grid>




 <Grid
  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(5, 1fr)" }}
  gap={6}
  width={{ base: '80vw', md: '90%',lg:"90%",xl:'90%' }}

>
  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8}  cursor="pointer" width={'100%'} height={'14vh'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="100%">
        <img src={img19} width="100%" height="100%"  />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Science</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8}  cursor="pointer" height={'14vh'} width={'100%'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img20} width="100%" height="100%"  />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Teaching</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8}  cursor="pointer" height={'14vh'} width={'100%'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img21} width="100%" height="100%"  />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Pharmacy</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8} cursor="pointer" width={'100%'} height={'14vh'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img22} width="100%" height="100%"  />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.1rem" marginTop="-0.5vh">Hotel <br/> Management</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>

  <GridItem background="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={8}  cursor="pointer" width={'100%'} height={'14vh'}>
    <Flex padding={'10px'}>
      <Box width="30%" height="60%">
        <img src={img23} width="100%" height="100%" alt="Law" />
      </Box>
      <Box width="70%" height="100%"
       textAlign="center" marginLeft={'1vw'}>
        <Text color="#FF6537" fontSize="1.2rem" marginTop="-0.5vh">Mechanic</Text>
        <Text color="black" fontSize="1rem">4050 Colleges</Text>
      </Box>
    </Flex>
  </GridItem>
</Grid>


  



  
</Box>
   
  </Box>

</Box>



<Box width={'100vw'} height={['80vh','80vh','70vh','70vh']} display={'flex'} justifyContent={'center'} alignItems={'center'} position={'relative'}>
  <Box display={'flex'} justifyContent={'center'} alignItems={'center'} position={'relative'} width={'80%'} height={'100%'}> 
    <HStack>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width={['100vw','100vw','50vw','50vw']}>
        <Text textAlign={'center'} color={'#00008B'} fontSize={['1.5rem','1.5rem','3rem','3rem']}>Don’t know where to start?</Text>
        <Box width={['80vw','80vw','36vw','36vw']}>
        <hr style={{ width: '100%', height: '0.3vh', background: '#FF6C00', border: 'none' }} />

        </Box>
       

        <br />

        <Text fontSize={'1.2rem'} textAlign={'center'} width={'100%'} marginLeft={'3vw'}>
          Discover Your Ideal College: Your Gateway to Higher Education Worldwide.
        </Text>
        <br />

        <Stack  direction={['column','column','row','row']}>
          <Box marginRight={'1.5vw'}>
            <Flex>
              <img src={img28} alt="Image 1" />
              <Text fontSize={'1.2rem'} marginLeft={'0.6vw'} fontWeight={'600'}>Extensive College Database</Text>
            </Flex>
            <br />
            <Flex>
              <img src={img28} alt="Image 2" />
              <Text marginLeft={'0.6vw'} fontSize={'1.2rem'} fontWeight={'600'}>Program Specializations</Text>
            </Flex>
            <br />
            <Flex>
              <img src={img28} alt="Image 3" />
              <Text marginLeft={'0.6vw'} fontSize={'1.2rem'} fontWeight={'600'}>Scholarship Opportunities</Text>
            </Flex>
          </Box>
          
          <Box marginTop={['2vh','2vh','none','none']}>
            <Flex>
              <img src={img28} alt="Image 4" />
              <Text marginLeft={'0.6vw'} fontSize={'1.2rem'} fontWeight={'600'}>Application Guidance</Text>
            </Flex>
            <br />
            <Flex>
              <img src={img28} alt="Image 5" />
              <Text marginLeft={'0.6vw'} fontSize={'1.2rem'} fontWeight={'600'}>Student Reviews</Text>
            </Flex>
            <br />
            <Flex>
              <img src={img28} alt="Image 6" />
              <Text marginLeft={'0.6vw'} fontSize={'1.2rem'} fontWeight={'600'}>Connect with Admissions</Text>
            </Flex>
          </Box>
        </Stack>
      </Box>
      <Box display={['none','none','block','block']}>
        <img src={img27} alt="Image 7" />
      </Box>
    </HStack>
  </Box>
</Box>




<Box className='universities' width="100vw" height={['120vh','60vh','60vh','60vh']} display="flex" justifyContent="center" alignItems="center" flexDirection="column" position="relative">
  <Text fontSize="2.575rem" color="#00008B">
    Top Universities
  </Text>
  <Box width={['80vw', '80vw', '20vw', '20vw']}>
    <hr style={{ width: '100%', height: '0.3vh', background: '#FF6C00', border: 'none' }} />
  </Box>
  <Box width="100%" marginTop={'8vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
    <Grid
      templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
      gap={6}
      width="80%"
      
    >
      <GridItem width="90%">
        <Box backgroundColor="white" width="100%" display="flex" justifyContent="center" alignItems="center">
          <img src={img30} style={{ padding: '10px' }} alt="Image 1" />
        </Box>
        <Text textAlign={'center'}>Lorem ipsum dolor sit amet,</Text>
      </GridItem>
      <GridItem width="90%">
        <Box backgroundColor="white" width="100%" display="flex" justifyContent="center" alignItems="center">
          <img src={img31} style={{ padding: '10px' }} alt="Image 2" />
        </Box>
        <Text textAlign={'center'}>Lorem ipsum dolor sit amet,</Text>
      </GridItem>
      <GridItem width="90%">
        <Box backgroundColor="white" display="flex" justifyContent="center" alignItems="center">
          <img src={img32} style={{ padding: '10px' }} alt="Image 3" />
        </Box>
        <Text textAlign={'center'}>Lorem ipsum dolor sit amet,</Text>
      </GridItem>
      <GridItem width="90%">
        <Box backgroundColor="white" display="flex" justifyContent="center" alignItems="center">
          <img src={img33} style={{ padding: '10px' }} alt="Image 4" />
        </Box>
        <Text textAlign={'center'}>Lorem ipsum dolor sit amet,</Text>
      </GridItem>
    </Grid>
  </Box>
  <Box display={['none','none','block','block']}>
  <img src={img34}  style={{ position: 'absolute', top: '0px', left: '0px' }} alt="Background Image 1" />
  <img src={img35} style={{ position: 'absolute', bottom: '0px', left: '0px' }} alt="Background Image 2" />
  </Box>
  
</Box>


<Box  width={'100vw'} height={['220vh','220vh','75vh','75vh']} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} position={'relative'}>
<Box textAlign={'center'} width={['75vw','75vw','29vw','29vw']} fontSize={['1.5rem','1.5rem','2.575rem','2.575rem']}>

  <Text color={'#00008B'}>What Our Student Says!</Text>

  

  <hr style={{marginTop:'-6vh',width:'100%',height:"0.3vh",background:'#FF6C00', border:'none' }} / >
</Box>

   <Grid
  templateColumns={{
    base: '1fr',
    md: 'repeat(2, 1fr)',
    lg: 'repeat(3, 1fr)',
    xl: 'repeat(4, 1fr)',
  }}
  gap={6}
  width="85vw"
>
<GridItem
    backgroundColor={'#00008B'}
    borderRadius={'10px'}
    height={'46vh'}
  >
    <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} >

            <Flex >
          <img src={thumb}  style={{textAlign:'center',marginRight:"0.5vh",marginLeft:"2vw"}} ></img> 
         <Text marginLeft={['-13vw','-6vw','-3vw','-3vw']} color={'white'} fontSize={'0.9rem'} >Testimonials</Text>
          </Flex>


<Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'75%'} height={'31vh'} backgroundColor={'white'} borderRadius={'13px'} margin={'auto 0px'}  marginTop={'4.5vh'} position={'relative'}>

            <Box position={'absolute'} top={['-14vw','-14vw','-3vw','-3vw']} left={['20.5vw','20.5vw','5.5vw','5.5vw']}>

              <img src={img36}></img>
<div class="five-star-rating">
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
  </div>

            </Box>

<Box textAlign={'center'} marginTop={'15vh'} >  
          

  <Box textAlign={'center'} marginTop={'-4vh'} fontSize={'0.8rem'}>
    <Text>If you are a business owner, and you don't have Xalor in your toolkit just yet, I highly recommend that you check it out.</Text>
  
    <Text fontWeight={'600'} marginTop={'-2vh'}>Agnes Lockman</Text>
    <Text fontSize={'0.85rem'} marginTop={'-2vh'}>Lead Operations Orchestrator</Text>
  </Box>
  </Box>





</Box>

         

          </Box>
    {/* Content of the second GridItem */}
  </GridItem>
  <GridItem
    backgroundColor={'#00008B'}
    borderRadius={'10px'}
    height={'46vh'}
  >
     <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} >

            <Flex >
          <img src={thumb}  style={{textAlign:'center',marginRight:"0.5vh",marginLeft:"2vw"}} ></img> 
         <Text marginLeft={['-13vw','-6vw','-3vw','-3vw']} color={'white'} fontSize={'0.9rem'} >Testimonials</Text>
          </Flex>


<Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'75%'} height={'31vh'} backgroundColor={'white'} borderRadius={'13px'} margin={'auto 0px'}  marginTop={'4.5vh'} position={'relative'}>

            <Box position={'absolute'} top={['-14vw','-14vw','-3vw','-3vw']} left={['20.5vw','20.5vw','5.5vw','5.5vw']}>

              <img src={img47}></img>
<div class="five-star-rating">
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
  </div>

            </Box>

<Box textAlign={'center'} marginTop={'15vh'} >  
          

  <Box textAlign={'center'} marginTop={'-4vh'} fontSize={'0.8rem'}>
    <Text>If you are a business owner, and you don't have Xalor in your toolkit just yet, I highly recommend that you check it out.</Text>
  
    <Text fontWeight={'600'} marginTop={'-2vh'}>Agnes Lockman</Text>
    <Text fontSize={'0.85rem'} marginTop={'-2vh'}>Lead Operations Orchestrator</Text>
  </Box>
  </Box>





</Box>

         

          </Box>
    {/* Content of the second GridItem */}
  </GridItem>
  <GridItem
    backgroundColor={'#00008B'}
    borderRadius={'10px'}
    height={'46vh'}
  >
   <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} >

            <Flex >
          <img src={thumb}  style={{textAlign:'center',marginRight:"0.5vh",marginLeft:"2vw"}} ></img> 
         <Text marginLeft={['-13vw','-6vw','-3vw','-3vw']} color={'white'} fontSize={'0.9rem'} >Testimonials</Text>
          </Flex>


<Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'75%'} height={'31vh'} backgroundColor={'white'} borderRadius={'13px'} margin={'auto 0px'}  marginTop={'4.5vh'} position={'relative'}>

            <Box position={'absolute'} top={['-14vw','-14vw','-3vw','-3vw']} left={['20.5vw','20.5vw','5.5vw','5.5vw']}>

              <img src={img48}></img>
<div class="five-star-rating">
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
  </div>

            </Box>

<Box textAlign={'center'} marginTop={'15vh'} >  
          

  <Box textAlign={'center'} marginTop={'-4vh'} fontSize={'0.8rem'}>
    <Text>If you are a business owner, and you don't have Xalor in your toolkit just yet, I highly recommend that you check it out.</Text>
  
    <Text fontWeight={'600'} marginTop={'-2vh'}>Agnes Lockman</Text>
    <Text fontSize={'0.85rem'} marginTop={'-2vh'}>Lead Operations Orchestrator</Text>
  </Box>
  </Box>





</Box>

         

          </Box>
    {/* Content of the second GridItem */}
  </GridItem>
  <GridItem
    backgroundColor={'#00008B'}
    borderRadius={'10px'}
    height={'46vh'}
  >
    <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} >

            <Flex >
          <img src={thumb}  style={{textAlign:'center',marginRight:"0.5vh",marginLeft:"2vw"}} ></img> 
         <Text marginLeft={['-13vw','-6vw','-3vw','-3vw']} color={'white'} fontSize={'0.9rem'} >Testimonials</Text>
          </Flex>


<Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'75%'} height={'31vh'} backgroundColor={'white'} borderRadius={'13px'} margin={'auto 0px'}  marginTop={'4.5vh'} position={'relative'}>

            <Box position={'absolute'} top={['-14vw','-14vw','-3vw','-3vw']} left={['20.5vw','20.5vw','5.5vw','5.5vw']}>

              <img src={img49}></img>
<div class="five-star-rating">
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
    <span class="star yellow-star"></span>
  </div>

            </Box>

<Box textAlign={'center'} marginTop={'15vh'} >  
          

  <Box textAlign={'center'} marginTop={'-4vh'} fontSize={'0.8rem'}>
    <Text>If you are a business owner, and you don't have Xalor in your toolkit just yet, I highly recommend that you check it out.</Text>
  
    <Text fontWeight={'600'} marginTop={'-2vh'}>Agnes Lockman</Text>
    <Text fontSize={'0.85rem'} marginTop={'-2vh'}>Lead Operations Orchestrator</Text>
  </Box>
  </Box>





</Box>

         

          </Box>
    {/* Content of the second GridItem */}
  </GridItem>
</Grid>

<Box display={['none','none','block','block']}>
    <img src={img37} style={{position:'absolute',bottom:'0px',left:'0px'}}></img>
    <img src={img38} style={{position:'absolute',top:'11vh',right:'0px'}}></img>
</Box>



</Box>



<Box className='universities' width={'100vw'} height={['230vh','120vh','75vh','75vh']} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} position={'relative'}>

  <Text fontSize={'2.575rem'} color={'#00008B'}>Blogs</Text>
<br/>

<Box width={['30vw','30vw','5vw','5vw']}>
<hr style={{width:'100%',height:"0.5vh",background:'#FF6C00', marginTop:'-2.5vh', border:'none' }} / >
</Box>

    <Grid
  templateColumns={{
    base: '1fr',
    md: 'repeat(2, 1fr)',
    lg: 'repeat(3, 1fr)',
    xl: 'repeat(4, 1fr)',
  }}
  gap={6}
  width="80vw"
>
  <GridItem

  ><Box width={'100%'} height={'60%'} boxShadow= {'15px 4px 64px rgba(0, 0, 0, 0.15)'} >
            <img src={img39} style={{ marginTop:'-3vh',
    width:'100%',height:'100%'}}></img>
          </Box>
  
  <Box width={'100%'} height={'41%'}  backgroundColor={'white'}>
           
  
            <Text marginLeft={'0.5vh'} marginTop={'-2vh'} fontSize={'0.7rem'} color={'#3939BC'}>08.08.2021
            
            </Text >
  
            <Text marginLeft={'0.5vh'}  marginTop={'-1vw'}  color={'#00008B'} fontWeight={'bold'}  width={'80%'} fontSize={'1rem'}>Thins to know before visiting Cave in Germany
            
  
  </Text>
  <Text marginLeft={'0.5vw'} fontSize={'0.6rem'} width={'80%'} color={'#333333'}>
  If you are a business owner, and you don't have Xalor in your toolkit just yet, 
  </Text>
  
  
          </Box>{/* Content of the first GridItem */}
  </GridItem>
  <GridItem

  ><Box width={'100%'} height={'60%'} boxShadow= {'15px 4px 64px rgba(0, 0, 0, 0.15)'} >
            <img src={img40} style={{ marginTop:'-3vh',
    width:'100%',height:'100%'}}></img>
          </Box>
  
  <Box width={'100%'} height={'41%'}  backgroundColor={'white'}>
           
  
            <Text marginLeft={'0.5vh'} marginTop={'-2vh'} fontSize={'0.7rem'} color={'#3939BC'}>08.08.2021
            
            </Text >
  
            <Text marginLeft={'0.5vh'}  marginTop={'-1vw'}  color={'#00008B'} fontWeight={'bold'}  width={'80%'} fontSize={'1rem'}>Thins to know before visiting Cave in Germany
            
  
  </Text>
  <Text marginLeft={'0.5vw'} fontSize={'0.6rem'} width={'80%'} color={'#333333'}>
  If you are a business owner, and you don't have Xalor in your toolkit just yet, 
  </Text>
  
  
          </Box>{/* Content of the first GridItem */}
  </GridItem>
  <GridItem

  ><Box width={'100%'} height={'60%'} boxShadow= {'15px 4px 64px rgba(0, 0, 0, 0.15)'} >
            <img src={img41} style={{ marginTop:'-3vh',
    width:'100%',height:'100%'}}></img>
          </Box>
  
  <Box width={'100%'} height={'41%'}  backgroundColor={'white'}>
           
  
            <Text marginLeft={'0.5vh'} marginTop={'-2vh'} fontSize={'0.7rem'} color={'#3939BC'}>08.08.2021
            
            </Text >
  
            <Text marginLeft={'0.5vh'}  marginTop={'-1vw'}  color={'#00008B'} fontWeight={'bold'}  width={'80%'} fontSize={'1rem'}>Thins to know before visiting Cave in Germany
            
  
  </Text>
  <Text marginLeft={'0.5vw'} fontSize={'0.6rem'} width={'80%'} color={'#333333'}>
  If you are a business owner, and you don't have Xalor in your toolkit just yet, 
  </Text>
  
  
          </Box>{/* Content of the first GridItem */}
  </GridItem>
  <GridItem

  ><Box width={'100%'} height={'60%'} boxShadow= {'15px 4px 64px rgba(0, 0, 0, 0.15)'} >
            <img src={img42} style={{ marginTop:'-3vh',
    width:'100%',height:'100%'}}></img>
          </Box>
  
  <Box width={'100%'} height={'41%'}  backgroundColor={'white'}>
           
  
            <Text marginLeft={'0.5vh'} marginTop={'-2vh'} fontSize={'0.7rem'} color={'#3939BC'}>08.08.2021
            
            </Text >
  
            <Text marginLeft={'0.5vh'}  marginTop={'-1vw'}  color={'#00008B'} fontWeight={'bold'}  width={'80%'} fontSize={'1rem'}>Thins to know before visiting Cave in Germany
            
  
  </Text>
  <Text marginLeft={'0.5vw'} fontSize={'0.6rem'} width={'80%'} color={'#333333'}>
  If you are a business owner, and you don't have Xalor in your toolkit just yet, 
  </Text>
  
  
          </Box>{/* Content of the first GridItem */}
  </GridItem>
</Grid>


   
    



</Box>


<Box width={'100vw'} height={'40vh'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} backgroundColor={'#00008B'} position={'relative'}>
  <Text fontSize={['1.2rem','1.2rem','1.7rem','1.7rem']} color={'white'}>For Any Further Queries Contact today!</Text>
  <Text  fontSize={'1.2rem'} color={'white'} opacity={'0.8'}>Call us Now</Text>
  <Text marginTop={'0.8vh'} fontSize={'1.2rem'} color={'white'}>+91 000000000</Text>
  <Text marginTop={'0.8vh'} backgroundColor={'#FF6C00'} color={'white'}  fontSize={'1.2rem'} paddingLeft={'2vw'} paddingRight={'2vw'} paddingTop={'0.7vw'} paddingBottom={'0.7vw'} borderRadius={'5px'}>Get in Touch</Text>



<Img src={img43} display={['none','none','block','block']} style={{ position: 'absolute', left: '1vw'  }}></Img>
  <Img src={img44} display={['none','none','block','block']} style={{ position: 'absolute', right: '10vw' }}></Img>
  <Img src={img45}  display={['none','none','block','block']} style={{ position: 'absolute', right: '2vw', bottom: '1vh'}}></Img>



</Box>

<Box>
  
</Box>



    </div>
    
  )
}

export default Home